.checkout {
		min-height: 100vh;
		padding-bottom: 7vh;
		margin-top: 3vh;

		@include media-breakpoint-up(md){
			min-height: 70vh;
		}

		@include media-breakpoint-between(md,lg) {
				background-image:
					 url("data:image/svg+xml,%3Csvg width='470' height='438' viewBox='0 0 470 438' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.23' d='M76.1141 318.907C34.6952 251.318 72.9026 152.536 161.453 98.2726C250.003 44.0089 355.364 54.8117 396.783 122.401C438.202 189.991 314.385 335.181 225.835 389.445C137.285 443.708 117.533 386.497 76.1141 318.907Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='151.11' y1='441.289' x2='465.261' y2='160.592' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='%239BD9EE'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A"),
					 url("data:image/svg+xml,%3Csvg width='696' height='538' viewBox='0 0 696 538' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M584.808 315.092C584.808 437.975 454.075 537.592 292.808 537.592C131.54 537.592 0.807618 437.975 0.807618 315.092C0.807617 192.208 282.54 100.592 443.808 100.592C605.075 100.592 584.808 192.208 584.808 315.092Z' fill='url(%23paint0_linear)'/%3E%3Cpath opacity='0.5' d='M693.808 215.092C693.808 276.155 661.328 331.508 608.677 371.628C556.024 411.748 483.242 436.592 402.808 436.592C322.373 436.592 249.591 411.748 196.939 371.628C144.287 331.508 111.808 276.155 111.808 215.092C111.808 184.775 129.185 156.258 158.015 130.48C186.835 104.711 226.982 81.7903 272.222 62.6817C362.704 24.4631 473.322 1.59177 553.808 1.59177C594.046 1.59177 622.84 7.30943 643.422 17.7193C663.966 28.1097 676.39 43.2057 683.853 62.1274C691.332 81.091 693.836 103.919 694.455 129.759C694.817 144.888 694.534 160.974 694.237 177.883C694.026 189.887 693.808 202.306 693.808 215.092Z' stroke='url(%23paint1_linear)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='584.808' y1='92.5916' x2='-58.8004' y2='209.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='694.808' y1='-7.40835' x2='51.1996' y2='109.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
				background-repeat:
					 no-repeat,
					 no-repeat;
				background-position:
					 -380px 65px,
					 calc(100% + 450px) -20px;
				background-size: auto, auto;
		}

		@include media-breakpoint-up(lg) {
				background-image:
					 url("data:image/svg+xml,%3Csvg width='470' height='438' viewBox='0 0 470 438' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.23' d='M76.1141 318.907C34.6952 251.318 72.9026 152.536 161.453 98.2726C250.003 44.0089 355.364 54.8117 396.783 122.401C438.202 189.991 314.385 335.181 225.835 389.445C137.285 443.708 117.533 386.497 76.1141 318.907Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='151.11' y1='441.289' x2='465.261' y2='160.592' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='%239BD9EE'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A"),
					 url("data:image/svg+xml,%3Csvg width='696' height='538' viewBox='0 0 696 538' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.1' d='M584.808 315.092C584.808 437.975 454.075 537.592 292.808 537.592C131.54 537.592 0.807618 437.975 0.807618 315.092C0.807617 192.208 282.54 100.592 443.808 100.592C605.075 100.592 584.808 192.208 584.808 315.092Z' fill='url(%23paint0_linear)'/%3E%3Cpath opacity='0.5' d='M693.808 215.092C693.808 276.155 661.328 331.508 608.677 371.628C556.024 411.748 483.242 436.592 402.808 436.592C322.373 436.592 249.591 411.748 196.939 371.628C144.287 331.508 111.808 276.155 111.808 215.092C111.808 184.775 129.185 156.258 158.015 130.48C186.835 104.711 226.982 81.7903 272.222 62.6817C362.704 24.4631 473.322 1.59177 553.808 1.59177C594.046 1.59177 622.84 7.30943 643.422 17.7193C663.966 28.1097 676.39 43.2057 683.853 62.1274C691.332 81.091 693.836 103.919 694.455 129.759C694.817 144.888 694.534 160.974 694.237 177.883C694.026 189.887 693.808 202.306 693.808 215.092Z' stroke='url(%23paint1_linear)' stroke-width='2'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='584.808' y1='92.5916' x2='-58.8004' y2='209.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='694.808' y1='-7.40835' x2='51.1996' y2='109.535' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.0246272' stop-color='#{$second-button}'/%3E%3Cstop offset='1' stop-color='#{$first-button}'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
				background-repeat:
					 no-repeat,
					 no-repeat;
				background-position:
					 -290px 75px,
					 calc(100% + 330px) 240px;
				background-size: auto, auto;
		}

		&__header {
			@include media-breakpoint-up(md) {
				border-bottom: 1px solid #E0E0E0;
			}
		}

		&__total-amount {
			font-family: 'Montserrat', serif;
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			color: #5E7891;
		}

		&__details-footer {
			max-width: 320px;
			background: #FFFFFF;
			box-shadow: 0 4px 40px rgba(14, 21, 85, 0.1);
			border-radius: 3px;
		}

		&__title {
				font-weight: 600;
				display: block;
				margin-bottom: 0;
				margin-right: 30px;

				&-block {
						display: flex;
						align-items: baseline;
						position: absolute;
						top: 60px;
						left: 206px;
				}
		}

		&__form {
				padding-bottom: 15px;

				&__label {
					text-transform: uppercase;
				}

				&__user-balance {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 12px 20px;
					max-width: 274px;
					height: 110px;
					margin-left: auto;
					margin-right: auto;
					background: #FFFFFF;
					border: 2px solid rgba(29, 15, 81, 0.1);
					border-radius: 6px;
					margin-bottom: 80px;

					@include media-breakpoint-up(md) {
						margin-bottom: 0;
						height: 130px;
						width: 170px;
						margin-left: 50px;
						margin-right: unset;
						padding: 18px 12px;
					}

					@include media-breakpoint-up(lg) {
						width: 215px;
						padding: 18px 20px;
					}

					&__wrapper {
						@include media-breakpoint-up(md) {
							display: flex;
							align-items: center;
							margin-bottom: 37px;
						}
					}

					&__title {
						font-weight: 600;
						font-size: 14px;
						color: $body-color;
						margin-bottom: 0;
					}

					&__balance {
						font-size: 24px;
						line-height: normal;
					}

					&__use-balance {
						font-weight: 600;
						font-size: 12px;
						line-height: 20px;
						letter-spacing: 0.05em;
						margin-bottom: 0;
					}
				}

				&__total-cart {
					background: #FFFFFF;
					border: 2px solid rgba(29, 15, 81, 0.1);
					border-radius: 6px;
					height: 170px;
					padding: 18px 20px;
					margin-bottom: 20px;

					@include media-breakpoint-up(md) {
						margin-bottom: 0;
					}

					@include media-breakpoint-between(md, lg) {
						width: 210px;
					}

					@include media-breakpoint-up(lg) {
						width: 255px;
					}

					&__title {
						font-weight: 500;
						font-size: 14px;

					}

					&__price {
						font-weight: 600;
						font-size: 36px;
						line-height: 39px;
						letter-spacing: 0.01em;
						margin-top: 36px;
					}
				}

				&__title {
					font-weight: 500;
					font-size: 18px;
					line-height: normal;
					letter-spacing: 0.01em;
					margin-bottom: 32px;
				}

				&__paymentRadio {
					&:not(:last-of-type) {
						margin-bottom: 30px !important;

						@include media-breakpoint-up(lg) {
							margin-bottom: 20px !important;
						}
					}

					&:last-of-type {
						margin-bottom: 40px !important;

						@include media-breakpoint-up(md) {
							margin-bottom: 0 !important;
						}
					}
				}

				&__radio{
						max-width: calc(100% - 15px);
						margin: 6px 0 19px 15px;

						& ~ & {
								margin-bottom: 11px;
						}
				}

				&__checkbox {
						margin: 11px 0 12px 15px;
				}

				&__input {
						margin-top: 11px;
						margin-bottom: 12px;

						&__note {
							margin-top: 68px;
						}
				}

				&__button {
						margin-top: 11px;
						margin-left: 15px;
						padding: 9px 0 10px;
						width: 181px;

						@include media-breakpoint-up(md) {
								margin-top: 38px;
						}
				}
		}

		&__footer {
				padding: 18px 0;
				position: relative;

				&:before {
						content: "";
						height: 1px;
						width:100vw;
						background-color: rgba(29, 15, 81, 0.1);
						position: absolute;
						top:0;
						transform: translateX(-50%);
						left: 50%;
				}

				@include media-breakpoint-up(md) {
						padding: 25px 0;
				}

				&__labels {
						display: flex;
						justify-content: space-between;
						padding: 8px 0;
						cursor: default;

						@include media-breakpoint-up(md) {
								justify-content: left;
								align-items: center;
								padding: 0;
						}
				}

				&__icons {
						justify-content: right;
						align-items: center;
						text-align: center;
						padding: 8px 0;

						@include media-breakpoint-up(md) {
								float: right;
								padding: 0;
						}
				}

				&__label {
						font-weight: $font-weight-semi-bold;
						line-height: 17px;
						cursor: pointer;
						font-size: 10px;
						letter-spacing: 0.05em;
						text-transform: uppercase;
						color: $body-color;

						@include media-breakpoint-between(md,lg) {
								margin-right: 16px;
						}

						@include media-breakpoint-up(lg) {
								margin-right: 50px;
						}
				}
				&__icon {
						margin-left: 23px;
						margin-right: 23px;

						@include media-breakpoint-up(md) {
								margin-right: 0;
								margin-left: 21px;
						}
						@include media-breakpoint-up(lg) {
								margin-left: 56px;
						}
				}
		}

		&__order-confirmation {
				line-height: 20px;
				margin-bottom: 0;
		}

		&__operation-ended {
				padding-bottom: 50px;
				&__label {
					font-size: 14px;
					margin-bottom: 50px;

					@include media-breakpoint-up(lg) {
						margin-bottom: 60px;
					}
				}

				&__button {
						display: block;
						max-width: 267px;
						box-shadow: none;
						margin-bottom: 10px;

						@include media-breakpoint-between(md,lg) {
								max-width: 270px;
						}

						@include media-breakpoint-up(lg) {
								max-width: 255px;
						}
				}
		}
}
