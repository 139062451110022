.games {

    @include media-breakpoint-up(lg) {
        .game:nth-child(1),
        .game:nth-child(2) {
            margin-top: 0;
        }
    }

    &__not-found {
        margin: 120px 0;

        @include media-breakpoint-up(lg) {
            margin-top: 150px;
        }

        &__label {
            color: $body-color;
            white-space: pre-line;

            @include media-breakpoint-up(md) {
                font-weight: 500;
            }
        }
    }

    &__btn {
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 20px;

        .btn {
            width: 181px;
            height: 51px;
        }
    }
}